$(function() {
    /*Placer le tableau point relais dans sa section respective*/
    // @A: Place it before h4
    $('.order_cgv .wrapperCgvTable').insertBefore("#bloc_5 h4:nth-of-type(2)");

    $('a.cms-page-module-link').click(function(){
        var hrefValue = $(this).attr("href");
        if (hrefValue.includes("https://site.booxi.eu/book_v2.html")) {
            DataLayerTrigger.rdvStore();
        }
    });
});