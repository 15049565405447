var AvailableInStore = {

    boxName: 'available-in-store',
    box: $('#available-in-store'),
    product: {}, // le produit et ses informations
    isAlreadyLoaded: false,
    loading: true,
    storeHtml: '', // le html des store
    nbKilometerMax: 50, // Le nombre de kilometre maximum pour la recherche du magasin

    // Montre la lightbox pour connaitre la disponibilité en magasin
    show: function() {
        var produitId = $('#produit_id').val();

        var couleurId = $('#couleurProd').val();
        var tailleId = $('#tailleProd').val();
        this.product = {
            'produit_id': produitId,
            'couleur_id': couleurId,
            'taille_id': tailleId,
        };

        // Ouverture de la ligthbox
        openMultiShad(this.boxName);
        if (!this.isAlreadyLoaded) {
            // Initialisation du template des stores
            if (this.storeHtml === '') {
                this.storeHtml = $('.store_locator .store', this.box)[0].outerHTML;
            }
            Mustache.parse(this.storeHtml);
            // Chargement du produit dans la lightbox
            this.load();
            this.isAlreadyLoaded = true;
        }
    },
    // Cache la lightbox magasin ou le produit est disponible
    close: function() {
        closeMultiShad(this.boxName);
    },
    /* Permet de récupérer tous les magasin disponible ainsi que leur stock en fonction du produit */
    load: function() {

        var self = this;
        var data = {
            'product_id' : this.product.product_id,
            'color_id' : this.product.color_id,
            'size_id' : this.product.size_id,
            'version' : 2,
        };

        $.ajax({
            type : 'post',
            url : path_relative_root + 'ajax_get_stores.php',
            data: data,
            success : function (boutique_bookinstore) {

                if (boutique_bookinstore) {
                    self.stores = $.parseJSON(boutique_bookinstore);

                    // Pour la restriction google
                    var countries = [];
                    var store = '';
                    var length = self.stores.length;
                    // Construction de la liste des pays disponibles
                    for (var i = 0 ; i < length ; i++) {
                        store = self.stores[i];
                        var doublon = countries.find(function (val) {
                            return val === store.iso;
                        });
                        if (doublon === undefined) {
                            countries.push(store.iso);
                        }
                    }

                    var options = {
                        types: ['geocode'],
                        style: google_map_style // dans le cas du chargement de la carte
                    };

                    if (countries && countries.length <= 5) {
                        options.componentRestrictions = {
                            country: countries
                        };
                    }

                    var eltAutoAdresse = document.getElementById('availableinstore_autoAddress');
                    // Initialisation de l'autocomplete
                    self.places = new google.maps.places.Autocomplete(eltAutoAdresse, options);

                    google.maps.event.addListener(self.places, 'place_changed', self.storeOnPlaceChange.bind());
                    google.maps.event.addDomListener(eltAutoAdresse, 'keydown', function(evt) {
                        // On bloque la touche enter
                        if (evt.keyCode === 13) {
                            evt.preventDefault();
                        }
                    });

                } else {
                    alertV2(Translator.translate('no_store_ereservation'));
                }
            }
        });
    },
    /* Event quand on change d'adresse */
    storeOnPlaceChange: function() {

        var storeLocatorC = $('.store_locator_container', AvailableInStore.box);
        storeLocatorC.removeClass('loaded');

        var place = AvailableInStore.places.getPlace();

        // Récupération de la latitude/longitude de la place trouvé pour le calcul de la distance avec tous les magasins
        var latitude = place.geometry.location.lat();
        var longitude = place.geometry.location.lng();

        // La liste de tous les magasins trié par distance
        var storesSort = AvailableInStore.storeSorting(latitude, longitude);

        // Maintenant qu'on a les magasin dans l'ordre de leur distance on va générer les blocs
        AvailableInStore.generateStoreBloc(storesSort);

        // On signale que les magasin sont chargés
        storeLocatorC.addClass('loaded');
    },
    // Permet de trier les magasins du plus proche au plus éloigné
    storeSorting: function(latitude, longitude) {

        var distance = 0;
        var storesSort = []; // La liste de tous les magasin ereservation ordonné par distance
        // On va parcourir chaque magasin trouvé pour calculer la distance entre le magasin et la position de l'adresse
        $.each(AvailableInStore.stores, function(index, store) {
            // Si la latitude exist
            if (store.lat != 0) {
                // Calcul de la distance avec formule haversine
                // Le calcul est le même que dans la fonction du BO en BDD)
                var radlon1 = (longitude * Math.PI) / 180;
                var radlon2 = (store.lng * Math.PI) / 180;
                var radlat1 = (latitude * Math.PI) / 180;
                var radlat2 = (store.lat * Math.PI) / 180;
                var dlo = (radlon2 - radlon1) / 2;
                var dla = (radlat2 - radlat1) / 2;
                var a = Math.sin(dla) * Math.sin(dla) + Math.cos(radlat2) * Math.sin(dlo) * Math.sin(dlo);
                distance = 6371 * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); // 6371 rayon de la terre
                store.distance = Math.round(distance * 100) / 100;
            }
            var length = storesSort.length;
            if (length > 0) {
                // Maintenant on va ordonner la liste des magasins au fur et à mesure
                for (var i = 0 ; i < length ; i++) {
                    // Si la valeur est plus petite que le premier element
                    if (store.distance < storesSort[i].distance) {
                        storesSort.splice(i, 0 , store);
                        break;
                    } else if (storesSort[i+1] !== undefined) { // si on a un élément suivant
                        // Si la distance du magasin est supérieur au courant et inférieur au suivant alors on a trouvé sa place
                        if (store.distance > storesSort[i].distance && store.distance < storesSort[i+1].distance) {
                            storesSort.splice(i+1, 0 , store);
                            break;
                        }
                    } else { // si plus d'élement suivant on met la valeur à la fin
                        storesSort.push(store);
                    }
                }
            } else {
                storesSort.push(store);
            }
        });

        return storesSort;
    },
    // Pour générer les bloc des magasins
    generateStoreBloc: function(stores) {

        var self = this;
        var storeLocator = $('.store_locator', AvailableInStore.box);

        // On vide le bloc
        storeLocator.html('');

        // Le nombre de résultats
        var nb_store = stores.length;
        // Le nombre de magasin assez proche
        var nbStoreAvailableNear = 0;

        var date_timezone;
        $.each(stores, function(index, store) {
            if (store.distance <= self.nbKilometerMax) {
                store.showSchedule = '';
                store.showAvailable = '';
                store.showNotAvailable = '';
                store.currentlyOpen = 'isClose';
                // On regarde si il y a un horaire de renseigner
                if (store.schedule === '') {
                    store.showSchedule = 'cache';
                } else {
                    // On regarde si le magasin est encore ouvert
                    // Récupérer de la date de l'utilisateur pour vérifier si le magasin est encore ouvert en fonction du timezone du magasin si définie
                    if (store.timezone !== '') {
                        date_timezone = new Date(new Date().toLocaleString("en-US",{timeZone:store.timezone}));
                    } else {
                        date_timezone = new Date();
                    }
                    var time = (date_timezone.getHours() < 10 ? '0' : '') + date_timezone.getHours();
                    time += (date_timezone.getMinutes() < 10 ? '0' : '') + date_timezone.getMinutes();

                    if (store.opening_until !== '') {
                        var opening = store.opening.replace(/:/g, '');
                        var closing = store.closing.replace(/:/g, '');
                    }
                    if (parseInt(opening) < parseInt(time) && parseInt(closing) > parseInt(time)) {
                        store.currentlyOpen = 'isOpen';
                    } else {
                        store.currentlyOpen = "isClose";
                        store.opening_until = Translator.translate('close');
                    }
                }
                // En fonction du stock on affichera si stock disponible ou non
                if (store.quantity > 0) {
                    store.showAvailable = 'cache';
                } else {
                    store.showNotAvailable = 'cache';
                }

                var html = Mustache.render(
                    self.storeHtml,
                    store
                );
                $(html).appendTo(storeLocator);
                nbStoreAvailableNear ++;
            }
        });
        // Le nombre de résultats
        $('#js_store_nb_result').html(nb_store);
        document.getElementById('js_store_nb_result').innerHTML = nbStoreAvailableNear;

        // Cas où aucune résultat
        var report = $('<div>', {class: 'js_store_report'});
        if (nb_store === 0) {
            report.html(Translator.translate('store_not_available'));
            $(report).appendTo(storeLocator);
        } else if (nbStoreAvailableNear === 0) {
            report.html(Translator.translate('store_not_available_near'));
            $(report).appendTo(storeLocator);
        }
    },
    // Geolocalise la personne et recherche en fonction du résultat
    geolocate: function() {
        var storeLocatorC = $('.store_locator_container', AvailableInStore.box);
        storeLocatorC.removeClass('loaded');
        navigator.geolocation.getCurrentPosition(
            function (position) {
                var lat = position.coords.latitude;
                var lng = position.coords.longitude;

                document.getElementById('availableinstore_autoAddress').value = '';

                var storesSort = AvailableInStore.storeSorting(lat, lng);

                // Maintenant qu'on a les magasin dans l'ordre de leur distance on va générer les blocs
                AvailableInStore.generateStoreBloc(storesSort);
                // On signale que les magasin sont chargés
                storeLocatorC.addClass('loaded');
            },
            function(error) {
                alertV2(error.message);
            }
        );
    }
}