$(function () {

    "use strict";

    var header = document.getElementById("site_head_wrap");
    var search = document.getElementById("search_home");
    var shade = document.getElementById("shad_menu");
    var filter = document.getElementById("main_filter_wrapper_container");

    function onWinScroll() {

        var scrollTop = $(document).scrollTop();

        if (header.classList.contains("sticky")) {
            if (scrollTop < 1) {

                header.classList.remove("sticky");
                shade.classList.remove("sticky");
                if (filter) {
                    filter.classList.remove("sticky");
                    $("#main_filter_wrapper_container").removeAttr("style");

                }
            }
        } else {

            if (scrollTop > 30) {
                header.classList.add("sticky");
                shade.classList.add("sticky");
                
                if (filter) {
                    filter.classList.add("sticky");
                    /* Auto correct filter rayon menu on all version with or without bandeau */
                    let getHeaderHeight = $('header').height();
                    $("#main_filter_wrapper_container.sticky").css('top', getHeaderHeight);
                }
            } else { 
                /* Auto correct filter rayon menu on all version with or without bandeau */
                $("#main_filter_wrapper_container").removeAttr("style");
            }
        }
    }

    function onSearchStateChange(evt) {

        if (evt.type === "focus") {

            search.parentElement.classList.add("searching");
        } else if (search.value.trim() === "") {

            search.parentElement.classList.remove("searching");
        }
    }

    $(window).on("scroll", onWinScroll);
    $(search).on({focus: onSearchStateChange, blur: onSearchStateChange});

    onWinScroll();

    var lastPlayedVideo = '';
    // Les différents swiper possible
    var swiperHome = ['cover_module_swiper', 'image_image_swiper'];
    // Player Youtube
    var youtubePlayers = {};
    // Player Vimeo
    var vimeoPlayers = {};
    // SLIDER HOME
    var id = 'cover_module_swiper';
    // store last played video to put in pause when slide
    var sliderObj = $('#' +id);

    // Si il y a un lecture youtube alors on va charger le script
    if ($('iframe.youtube_video').length >= 1) {
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(tag, firstScript);
    }

        if (sliderObj.length) {
            //init and store all youtube players
            if ($('#' + id + ' .swiper-slide iframe.youtube_video').length >= 1) {

                window.onYouTubeIframeAPIReady = function () {
                    $('#' + id + ' .swiper-slide iframe.youtube_video').each(function () {
                        var videoId = $(this).attr('id');
                        var playerYoutube = new YT.Player(videoId, {
                            //add a listener to start the slider autoplay again
                            events: {
                                onStateChange: function (evt) {
                                    if (evt.data === YT.PlayerState.ENDED) {
                                        evt.target.seekTo(0);
                                        evt.target.pauseVideo();
                                        if (swiperHome[id].autoplay !== undefined) {
                                            swiperHome[id].autoplay.start();
                                        }
                                    }
                                },
                                onReady: function () {
                                    // Si la video est en autoplay il faut stopper le swiper
                                    if (YT.PlayerState.PLAYING) {
                                        if (swiperHome[id].autoplay !== undefined) {
                                            swiperHome[id].autoplay.stop();
                                        }
                                    }
                                },
                            }
                        });
                        youtubePlayers[videoId] = playerYoutube;
                    });

                };
            }

            // Cas Video Vimeo
            if ($('#'+id+' .swiper-slide iframe.vimeo_video').length >= 1) {
                $('#'+id+' .swiper-slide iframe.vimeo_video').each(function() {
                    var videoId = $(this).attr('id');
                    var playerVimeo = new Vimeo.Player($(this));

                    //add a listener to start the slider autoplay again
                    playerVimeo.on('ended', function() {
                        if (swiperHome[id].autoplay !== undefined) {
                            swiperHome[id].autoplay.start();
                        }
                    });
                    vimeoPlayers[videoId] = playerVimeo;
                });
            }
            initSlider(id, '#');
        }

        var id2 = 'image_image_swiper';
        var sliderObj2 = $('#' +id2);

        $( ".image_image_swiper" ).each(function( index ) {
        if (sliderObj2.length) {

            //init and store all youtube players
            if ($('#' + id2 + ' .swiper-slide iframe.youtube_video').length >= 1) {
                window.onYouTubeIframeAPIReady = function () {
                    $('#' + id2 + ' .swiper-slide iframe.youtube_video').each(function () {
                        var videoId = $(this).attr('id');
                        var playerYoutube = new YT.Player(videoId, {
                            //add a listener to start the slider autoplay again
                            events: {
                                onStateChange: function (evt) {
                                    if (evt.data === YT.PlayerState.ENDED) {
                                        evt.target.seekTo(0);
                                        evt.target.pauseVideo();
                                        if (swiperHome[id2].autoplay !== undefined) {
                                            swiperHome[id2].autoplay.start();
                                        }
                                    }
                                },
                                onReady: function () {
                                    // Si la video est en autoplay il faut stopper le swiper
                                    if (YT.PlayerState.PLAYING) {
                                        if (swiperHome[id2].autoplay !== undefined) {
                                            swiperHome[id2].autoplay.stop();
                                        }
                                    }
                                },
                            }
                        });
                        youtubePlayers[videoId] = playerYoutube;
                    });

                };
            }

            // Cas Video Vimeo
            if ($('#'+id2+' .swiper-slide iframe.vimeo_video').length >= 1) {
                $('#'+id2+' .swiper-slide iframe.vimeo_video').each(function() {
                    var videoId = $(this).attr('id');
                    var playerVimeo = new Vimeo.Player($(this));

                    //add a listener to start the slider autoplay again
                    playerVimeo.on('ended', function() {
                        swiperHome[id2].autoplay.start();
                    });
                    vimeoPlayers[videoId] = playerVimeo;
                });
            }
            initSlider(id2, '.');
        }
    });

    function initSlider(idHome, elm) {
        var sliderObj = $(elm +idHome);
        var spaceBetween = sliderObj.data('spacebetween');
        var moduleClass = sliderObj.data('moduleclass');
        swiperHome[idHome] = new Swiper(elm + idHome, {
            slidesPerView: sliderObj.data('slideperview'),
            spaceBetween: spaceBetween ? spaceBetween : 0,
            autoplay: {
                delay: 5000,
            },
            speed: 2000,
            navigation: {
                nextEl: '.' + moduleClass + ' .swiper-button-next',
                prevEl: '.' + moduleClass + ' .swiper-button-prev'
            },
            pagination: {
                el: '.' + moduleClass + '.swiper-pagination',
                clickable: true,
                type: 'bullets'
            },
            on: {
                slideChangeTransitionEnd: function () {
                    //when a video is displayed
                    //stop the old one
                    //plays the new one
                    //stop the slider until the end
                    //store the video as last played
                    if ($('.swiper-slide-active', this.$el).find('iframe.vimeo_video').length) {
                        swiperHome[idHome].autoplay.stop();
                        $('.swiper-slide-active', this.$el).find('iframe.vimeo_video').each(function () {
                            var videoId = $(this).attr('id');
                            setLastVideoPause(videoId);
                            vimeoPlayers[videoId].play();
                            lastPlayedVideo = videoId;
                        });
                    } else if ($('.swiper-slide-active', this.$el).find('iframe.youtube_video').length) {
                        swiperHome[idHome].autoplay.stop();
                        $('.swiper-slide-active', this.$el).find('iframe.youtube_video').each(function () {
                            var videoId = $(this).attr('id');
                            setLastVideoPause(videoId);
                            youtubePlayers[videoId].playVideo();
                            lastPlayedVideo = videoId;
                        });
                    }
                }
            }
        });
    }

    //with the stored, put the last video in pause
    function setLastVideoPause(newPlayedVideo) {
        if (lastPlayedVideo !== '') {
            if($('#'+lastPlayedVideo).hasClass('vimeo_video')) {
                vimeoPlayers[lastPlayedVideo].pause();
            }
            else if($('#'+lastPlayedVideo).hasClass('youtube_video')) {
                youtubePlayers[lastPlayedVideo].pauseVideo();
            }
            lastPlayedVideo = newPlayedVideo;
        }
    }

    // Initialisation du slider si présent (tete de gondole)
    var swiperContainer = document.getElementById('homeSwiper');
    if (swiperContainer) {
        new Swiper('#homeSwiper', {
            slidesPerView: 2,
            spaceBetween: 66,
            loop: false,
            navigation: {
                nextEl: '.slider-module .swiper-button-next',
                prevEl: '.slider-module .swiper-button-prev'
            }
        });
    }
});
