(function () {

	"use strict";

    var req = new XMLHttpRequest();
    var onStateChange = function () {

        if (this.readyState === 4 && this.status === 200) {

            var cart, resp = this.responseText.trim();

            try {

            	resp = JSON.parse(resp);
            	cart = document.getElementById("cart_top").firstElementChild;

            	cart.setAttribute("data-cart", parseFloat(resp.cart_length));
            } catch (err) {

            	console.error(err);
            }
        }
    };

    req.addEventListener("readystatechange", onStateChange);

    req.open("GET", path_relative_root + "my_account_data.php", true);
    req.send(null);

	// function onMyAccountLoad() {

	// 	var label, endWidth;

	// 	if (!this.loaded) {

	// 		this.target.innerHTML = this.render;

	// 		label = document.getElementById("entete_connexion");

	// 		label.style.width = "auto";

	// 		endWidth = getComputedStyle(label).width;

	// 		label.style.width = 0;
	// 		label.style.transition = "width 0.4s ease-out, opacity 0.4s linear 0.3s";

	// 		// Affichage d'un des magasins favori dans l'entête (si le client à au moins un magasin favori)
	// 		if (this.view_data.magasin_id && this.view_data.magasin_horaires) {

	// 			var fav_store_btn = document.getElementById('topMenuFavoriteStore');
	// 			var store_lien = path_relative_root + create_link('satellite_store_details') + '?id=' + this.view_data.magasin_id;

	// 			if (!this.view_data.magasin_open) {

	// 				fav_store_btn.classList.add('closed');
	// 			}

	// 			fav_store_btn.innerHTML = '<div><span>' + this.view_data.magasin_nom + '</span><small>' + this.view_data.magasin_horaires + '</small></div>';
	// 			fav_store_btn.href = store_lien;
	// 		}

	// 		if (this.view_data.wishlist_length > 0 && document.getElementById('wishlist_length')) {

	// 			document.getElementById('wishlist_length').innerHTML = this.view_data.wishlist_length;

	// 			$('#btn_top_wishlist').slideDown(400);
	// 		}

	// 		if (!label.classList.contains('loaded')) {

	// 			window.setTimeout(function () {

	// 				label.classList.add('loaded');

	// 				label.style.width = endWidth;
	// 				label.style.opacity = 1;

	// 				window.setTimeout(function () {

	// 					label.style.transition = "all 0 ease 0";
	// 					label.style.width = "auto";
	// 				}, 700);
	// 			}, 50);
	// 		}

	// 		this.loaded = true;
	// 	}

	// 	/*document.getElementById('cart_length').innerHTML = this.view_data.cart_length || '0';
	// 	document.getElementById('cart_unit').innerHTML = this.view_data.cart_amount_u || '0';
	// 	document.getElementById('cart_cent').innerHTML = this.view_data.cart_amount_d || '00';*/
	// }

	// return new Partial("show_top_cart", path_relative_root + "my_account_data.php", "my_account_tmpl", onMyAccountLoad);
}());
