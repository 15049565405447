// TUNNEL MOVE DISCLAIMER
$(function () {

    // Moving elements other divs in Tunnel
    var source;
    var destination;
    
    // Move Beware message to correct place
    if ($('body.cart').length) {
        if ($('.cart.body_login').length < 1 && $('.cart.step_2').length < 1) {
            source = $('.beware_msg_wrapper');
            destination = $('.left_side.elem_tunnel');
    
            moveElemTo(source, destination, 'append');
        }
    } else {
        if ($('.beware_msg_wrapper').length) {
            $('.beware_msg_wrapper').hide();
        }
    }

    // Move address form error from step_2_0 to correct place
    if ($('body.step_2_0 #mes_err').length) {
        source = $('#mes_err');
        destination = $('.left_side.elem_tunnel.addressWrapper');

        moveElemTo(source, destination, 'prepend');
    }

    if ($('.cart.step_2 .bloc_livraison .title_horraire').length) {
        $('.cart.step_2 .bloc_livraison .title_horraire').on('click', function() {
            if (!$(this).hasClass('deployed')) {
                if ($(this).parent().find('.horaire').length > 0) {
                    $(this).addClass('deployed');
                    $(this).parent().find('.horaire').slideDown(500);
                }
            } else {
                if ($(this).parent().find('.horaire').length > 0) {
                    $(this).removeClass('deployed');
                    $(this).parent().find('.horaire').slideUp(500);
                }
            }
        });
    }
});

function moveElemTo(source, destination, method) {
    // We use show() because those elements are initially hidden to prevent bad visuals from the action
    if(destination.length) {
        if(method == 'prepend') {
            source.prependTo(destination);
        } else {
            source.appendTo(destination);
        }
        source.show();
    }
}