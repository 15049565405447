var look_add = false;

function addLook(loader) {

    $(loader).fadeIn(200);

    look_add = true;

    calculTotal('slide_look_detail', true);
}

function calculTotal(id, process_look) {

    var $form = $(document.getElementById(id));
    var data = $form.serialize();

    $.ajax({
        type : 'post',
        url : path_relative_root + 'ajax_calcul_total.php',
        data : data,
        success : function (response) {

            try {

                response = JSON.parse(response);
            } catch (e) {

                console.error('parseJSON');

                return;
            }

            if (response.success == 'ok') {

                var datas = response.datas;
                var total = datas.total;

                $('#look_total', '#look_info').val(total);
                $('.price_total', '#look_info').html(datas.total_format);

                if (process_look) {

                    if (total > 0) {

                        if (!addToCartTmpl) {

                            addToCartTmpl = document.getElementById('modbox').innerHTML.trim();

                            Mustache.parse(addToCartTmpl);
                        }

                        buildLookAddedModbox(false);
                        processlook();
                    } else {

                        var $alertbox = $( '#alertbox1' );

                        res = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_error_quantity');

                        html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
                        html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';
                        if ($alertbox.length == 0) {
                            $alertbox = $('<div id="alertbox1">'+ html_err +'</div>');
                            $('body').append($alertbox.hide());
                        }

                        $alertbox.find('.txt_alert').html(res);
                            openMultiShad('alertbox1');

                        $('#bloc_btn_look').show();
                        $('#bloc_btn_loader_look').hide();

                        if ($('.loader', '#bloc_add_basket').length > 0)  {

                            $('.loader', '#bloc_add_basket').hide();
                        }
                    }
                }
            }
        }
    });
}

function loadPrevisu() {

    var cpt = 0,
        $array_product_previsu = [];

    $('.array_produit_total').map(function () {

        var $current_product = $(this).val();
        var $current_product_qte = $('#qteProd_' + $current_product).val();

        if ($current_product_qte > 0) {

            $array_product_previsu[cpt] = [];

            $array_product_previsu[cpt]['id'] = $current_product;
            $array_product_previsu[cpt]['img'] = $('#img_produit_' + $current_product).attr('src');
            $array_product_previsu[cpt]['price'] = $('#price_' + $current_product).html();
            $array_product_previsu[cpt]['qte'] = $current_product_qte;

            cpt++;
        }
    }).get();

    return $array_product_previsu;
}

function processlook() {

    $('.submit_panier').each(function () {

        var $elem = $(this).attr('id');
        var $id = $elem.substring($elem.lastIndexOf("_") + 1);
        var $val_item = $("#qteProd_" + $id).val();

        if ($val_item > 0) {

            $("#look_add_lightbox_" + $id).show();
            $(this).click();
        } else {

            $("#look_add_lightbox_" + $id).hide();
        }
    });

    setTimeout(showsendfriendmodal, 1000, 0, path_relative_root, 'look_basket');
}
